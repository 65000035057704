import {IQuestion, Operands} from "./IQuestion"

export default class QuestionHelper {


    public static generateQuestion(digitMax: number, operand: Operands): IQuestion {
        const a = this.getRandomInt(digitMax)
        const b = this.getRandomInt(digitMax)

        let x = a
        let y = b
        let result = 0

        switch (operand) {
            case Operands.PLUS:
                result = a + b
                break
            case Operands.MINUS:
                if (b > a) {
                    x = b
                    y = a
                    result = b - a
                }
                break
            case Operands.MULTIPLY:
                result = a * b
                break
            case Operands.DIVIDE:
                x = a * b
                y = b
                result = a
                break
        }
        return {
            x: x,
            y: y,
            operand: operand,
            result: result
        }

    }

    public static generateQuestion2(digitMin: number, digitMax: number, operand: Operands): IQuestion {
        const a = this.getRandomInt2(digitMin, digitMax)
        const b = this.getRandomInt2(digitMin,digitMax)

        console.log("a=" + a + " b=" + b)

        let x = a
        let y = b
        let result = 0

        switch (operand) {
            case Operands.PLUS:
                result = a + b
                break
            case Operands.MINUS:
                if (b > a) {
                    x = b
                    y = a
                    result = b - a
                }
                break
            case Operands.MULTIPLY:
                result = a * b
                break
            case Operands.DIVIDE:
                x = a * b
                y = b
                result = a
                break
        }
        return {
            x: x,
            y: y,
            operand: operand,
            result: result
        }

    }



    /**
     *
     * @param max - max for the generated digit
     * @private
     */
    private static getRandomInt(digitMax: number) {
        return Math.floor(1 + Math.random() * digitMax)
    }

    private static getRandomInt2(digitMix: number, digitMax: number) {
        const min = Math.ceil(digitMix)
        const max = Math.floor(digitMax)
        return Math.floor(Math.random() * (max - min + 1)) + min
    }
}