import {Button, Card, CardActionArea, CardContent, Chip, Container, Grid, TextField} from "@mui/material"
import React, {useState} from "react"
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import {useLocation} from 'react-router-dom'
import {IQuestion, Operands} from "../utils/IQuestion"
import QuestionHelper from "../utils/QuestionHelper"


type Question = {
    id?: number
    q: IQuestion
    myAnswer: number
}


export default function Quiz2() {

    const [questions, setQuestions] = useState<Question[]>()
    const [started, setStarted] = useState(false)
    const [showResult, setShowResult] = useState(false)

    // set the max for the generated digit
    let maxGeneratedNumber = 11 //default
    let minGeneratedNumber = 0
    let operand = Operands.MULTIPLY

    const {search} = useLocation()
    const urlSearchParams = new URLSearchParams(search.split("?")[1])
    const paramMin = urlSearchParams.get("min")
    if (paramMin)
        minGeneratedNumber = parseInt(paramMin)

    const max = urlSearchParams.get("max") // ?max=4 is the query param
    if (max)
        maxGeneratedNumber = parseInt(max)


    const paramOperand = urlSearchParams.get("operand")
    if (paramOperand)
        if (paramOperand === '1')
            operand = Operands.PLUS
        else if (paramOperand === '2')
            operand = Operands.MINUS
        else if (paramOperand === '3')
            operand = Operands.MULTIPLY
        else if (paramOperand === '4')
            operand = Operands.DIVIDE

    // set the max number of questions
    let numQuestions = 10 //default
    const numQ = urlSearchParams.get("numQuestions")
    if (numQ)
        numQuestions = parseInt(numQ)


    const generateQuestions = () => {
        let questions: Question[] = []

        for (let i = 0; i < numQuestions; i++) {
            const generateQuestion = QuestionHelper.generateQuestion2(minGeneratedNumber, maxGeneratedNumber, operand)
            questions.push({
                id: questions.length,
                q: generateQuestion,
                myAnswer: -999
            })
        }

        console.log("===Generated Questions===", questions)
        return questions
    }

    const setAnswer = (id: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e && questions) {
            questions[id].myAnswer = parseInt(e.target.value)
            setQuestions(questions)
        }
    }

    const handleCheckAnswer = () => {
        // console.log("show result", showResult)
        setShowResult(true)
        // console.log("//check answer", questions)
    }


    const newQuestions = () => {
        setQuestions(undefined)
        const qs = generateQuestions()
        setQuestions(qs)
        setStarted(true)
    }


    const isAnswerCorrect = (id: number) => {
        if (questions) {
            const q = questions[id]
            return q.myAnswer === q.q.result
        }
        return false
    }

    const getScore = () => {
        let score = 0
        if (questions) {
            questions.forEach(q => {
                if (q.myAnswer === q.q.result)
                    score++
            })
        }
        return score
    }

    const getOperandStr = (operand: Operands) => {
        if (operand === Operands.MULTIPLY)
            return 'x'
        else if (operand === Operands.DIVIDE)
            return '÷'
        else if (operand === Operands.PLUS)
            return '+'
        else if (operand === Operands.MINUS)
            return '-'

    }

    return (
        <Container>

            <h1>Welcome to the Math Quiz - v2</h1>

            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                {
                    !questions ? <></> :
                        questions.map(q => {
                            return (
                                <Grid xs={12} md={6}>
                                    <Card key={q.id}
                                          sx={{
                                              maxWidth: 500,
                                              background: "#c8e3bd",
                                              padding: 2,
                                              paddingBottom: 3,
                                              margin: 3
                                          }}>
                                        <CardActionArea>
                                            Question.{q.id}
                                            <CardContent>
                                                <Chip sx={{marginLeft: 1, marginTop: 2}} label={q.q.x}
                                                      color="warning"/>
                                                <Chip sx={{marginLeft: 1, marginTop: 2}}
                                                      label={getOperandStr(q.q.operand)}/>
                                                <Chip sx={{marginLeft: 1, marginTop: 2}} label={q.q.y}
                                                      color="success"/>
                                                <Chip sx={{marginLeft: 1, marginRight: 1, marginTop: 2}} label="="/>
                                                <TextField id={"ans-" + q.id} sx={{marginLeft: 2}} label="Answer"
                                                           variant="standard"
                                                           onChange={e => setAnswer(q.id!, e)}
                                                />
                                                {!showResult ? <></> :
                                                    isAnswerCorrect(q.id!) ?
                                                        <Chip variant="outlined" label={<CheckIcon/>} color="success"
                                                              style={{float: "right", margin: 15}}/>
                                                        :
                                                        <Chip label={<ClearIcon/>} color="error"
                                                              style={{float: "right", margin: 15}}/>
                                                }

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )

                        })}

            </Grid>

            {started && !showResult ?
                <Button variant="contained" sx={{my: 6}} onClick={handleCheckAnswer} color={"success"} size={"large"}
                        style={{zoom: 3}}>
                    Check Answer
                </Button> :

                started ?
                    <div>
                        <h3> Your score is: {getScore() + "/" + questions?.length} </h3>

                        {
                            getScore() === questions?.length ?
                                <div style={{float: "right", margin: 15}}><h2>Perfect !</h2>
                                    <img src="https://static.wikia.nocookie.net/sanrio/images/1/10/Hello-kitty.png" width={500} alt={"perfect score"}/></div> :
                                <div style={{float: "right", margin: 15}}><h2>Good luck next time !</h2>
                                    <img src="https://1.bp.blogspot.com/-oXNr_fWuZZA/VGmOyqtvYVI/AAAAAAAAaYk/YyFp6mX-ANU/s1600/Hello%2BKitty%2BCake.jpg" width={500} alt={"failed test"}/></div>
                        }


                        <Button variant="contained" sx={{my: 6}} onClick={() => window.location.reload()}
                                color={"error"}>
                            Start Over
                        </Button>

                    </div>
                    : ""
            }

            {!started ?
                <Button variant="contained" sx={{my: 6}}
                        onClick={newQuestions}>
                    Start new Quiz
                </Button> : ""
            }


        </Container>
    )

}