import {Button, Card, CardActionArea, CardContent, Chip, Container, TextField} from "@mui/material";
import React, {useState} from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';


type Question = {
    id?: number
    x: number
    y: number
    result: number,
    answer?: string
}

const MAX_NUMBER = 10


export default function Multiplication() {

    const [questions, setQuestions] = useState<Question[]>()
    const [started, setStarted] = useState(false)
    const [showResult, setShowResult] = useState(false)


    function getRandomInt() {
        return Math.floor(Math.random() * MAX_NUMBER) + 1;

    }

    const multiply = (a: number, b: number) => {
        return a * b
    }

    const generateMultiplicationQuestion = (): Question => {
        const a = getRandomInt()
        const b = getRandomInt()
        console.log({
            x: a,
            y: b,
            result: multiply(a, b)
        })
        return {
            x: a,
            y: b,
            result: multiply(a, b)
        }
    }

    const generateQuestions = (numQ: number) => {
        let questions: Question[] = []

        for (let i = 0; i < numQ; i++) {
            const q = generateMultiplicationQuestion()
            q.id = i

            questions.push(q)
        }

        return questions
    }

    const setAnswer = (id: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e && questions) {
            questions[id].answer = e.target.value
            setQuestions(questions)
        }
    }

    const handleCheckAnswer = () => {
        console.log("show result", showResult)
        setShowResult(true)
        console.log("//check answer", questions)
    }


    const newQuestions = () => {
        setQuestions(undefined)
        const qs = generateQuestions(10)
        setQuestions(qs)
        setStarted(true)
        console.log('new generated questions', questions)
    }


    const isAnswerCorrect = (id: number) => {
        if (questions) {
            const q = questions[id]
            return q.answer === q.result + ""
        }
        return false
    }

    const getScore = () => {
        const total = questions?.length
        let score = 0
        if (questions) {
            questions.forEach(q => {
                if (q.answer === q.result + "")
                    score++
            })
        }
        return score + "/" + total
    }


    return (
        <Container>


            <h1>Quiz</h1>

            <h2>Multiplication</h2>

            {
                !questions ? <></> :
                    questions.map(q => {
                        return (
                            <Card key={q.id} sx={{maxWidth: 500, background: "lightblue", paddingBottom: 1, margin: 3}}>
                                <CardActionArea>
                                    <CardContent>
                                        <Chip sx={{marginLeft: 1, marginRight: 1, marginTop: 2}} label={q.x}
                                              color="warning"/>
                                        <Chip sx={{marginLeft: 1, marginRight: 1, marginTop: 2}} label="✕"/>
                                        <Chip sx={{marginLeft: 1, marginRight: 1, marginTop: 2}} label={q.y}
                                              color="success"/>
                                        <Chip sx={{marginLeft: 1, marginRight: 1, marginTop: 2}} label="🟰"/>
                                        <TextField id={"ans-" + q.id} sx={{marginLeft: 2}} label="Answer"
                                                   variant="standard"
                                                   onChange={e => setAnswer(q.id!, e)}

                                        />
                                        {!showResult ? <></> :
                                            isAnswerCorrect(q.id!) ?
                                                <Chip label={<CheckIcon/>} color="success"/>
                                                :
                                                <Chip label={<ClearIcon/>} color="error"/>
                                        }

                                    </CardContent>
                                </CardActionArea>
                            </Card>


                        )

                    })}


            {started && !showResult ?
                <Button variant="contained" sx={{my: 6}} onClick={handleCheckAnswer} color={"success"}>
                    Check Answer
                </Button> :

                started ?
                    <div>
                        <h3> Your score is: {getScore()} </h3>
                        <Button variant="contained" sx={{my: 6}} onClick={() => window.location.reload()}
                                color={"error"}>
                            Start Over
                        </Button>
                    </div>
                    : ""
            }

            {!started ?
                <Button variant="contained" sx={{my: 6}}
                        onClick={newQuestions}>
                    Start new Quiz
                </Button> : ""
            }


        </Container>
    )

}