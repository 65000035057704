export interface IQuestion{
    x: number
    y: number
    operand: Operands
    result: number
}


export enum Operands {
    PLUS='1',
    MINUS='2',
    MULTIPLY='3',
    DIVIDE='4'
}