import {Button, Card, CardActionArea, CardContent, Chip, Container, Grid, TextField} from "@mui/material"
import React, {useState} from "react"
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import {useLocation} from 'react-router-dom'
import {IQuestion, Operands} from "../utils/IQuestion"
import QuestionHelper from "../utils/QuestionHelper"


type Question = {
    id?: number
    q: IQuestion
    myAnswer: number
}


export default function Quiz() {

    const [questions, setQuestions] = useState<Question[]>()
    const [started, setStarted] = useState(false)
    const [showResult, setShowResult] = useState(false)

    // set the max for the generated digit
    let maxGeneratedNumber = 11 //default
    const {search} = useLocation()
    const urlSearchParams = new URLSearchParams(search.split("?")[1])
    const max = urlSearchParams.get("max") // ?max=4 is the query param
    if (max)
        maxGeneratedNumber = parseInt(max)


    // set the max number of questions
    let numQuestions = 10 //default
    const numQ = urlSearchParams.get("numQuestions") // ?max=4 is the query param
    if (numQ)
        numQuestions = parseInt(numQ)


    const generateQuestions = () => {
        let questions: Question[] = []
        const numMultiply = numQuestions / 2

        // multiply
        for (let i = 0; i < numMultiply; i++) {
            const generateQuestion = QuestionHelper.generateQuestion(maxGeneratedNumber, Operands.MULTIPLY)
            questions.push({
                id: questions.length,
                q: generateQuestion,
                myAnswer: -999
            })
        }

        // division
        for (let i = numMultiply; i < numQuestions; i++) {
            const generateQuestion = QuestionHelper.generateQuestion(maxGeneratedNumber, Operands.DIVIDE)
            questions.push({
                id: questions.length,
                q: generateQuestion,
                myAnswer: -999
            })
        }

        console.log("===Generated Questions===", questions)
        return questions
    }

    const setAnswer = (id: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e && questions) {
            questions[id].myAnswer = parseInt(e.target.value)
            setQuestions(questions)
            //
            // console.log("set id", id)
            // console.log("after set question", questions)
        }
    }

    const handleCheckAnswer = () => {
        // console.log("show result", showResult)
        setShowResult(true)
        // console.log("//check answer", questions)
    }


    const newQuestions = () => {
        setQuestions(undefined)
        const qs = generateQuestions()
        setQuestions(qs)
        setStarted(true)
    }


    const isAnswerCorrect = (id: number) => {
        if (questions) {
            const q = questions[id]
            return q.myAnswer === q.q.result
        }
        return false
    }

    const getScore = () => {
        let score = 0
        if (questions) {
            questions.forEach(q => {
                if (q.myAnswer === q.q.result)
                    score++
            })
        }
        return score
    }

    return (
        <Container>


            <h1>Welcome to the Math Quiz</h1>

            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                {
                    !questions ? <></> :
                        questions.map(q => {
                            return (
                                <Grid xs={12} md={6}>
                                    <Card key={q.id}
                                          sx={{
                                              maxWidth: 500,
                                              background: "#FCE4EC",
                                              padding: 2,
                                              paddingBottom: 3,
                                              margin: 3
                                          }}>
                                        <CardActionArea>
                                            Question.{q.id}
                                            <CardContent>
                                                <Chip sx={{marginLeft: 1, marginTop: 2}} label={q.q.x}
                                                      color="warning"/>
                                                <Chip sx={{marginLeft: 1, marginTop: 2}}
                                                      label={q.q.operand === Operands.MULTIPLY ? "x" : "÷"}/>
                                                <Chip sx={{marginLeft: 1, marginTop: 2}} label={q.q.y}
                                                      color="success"/>
                                                <Chip sx={{marginLeft: 1, marginRight: 1, marginTop: 2}} label="="/>
                                                <TextField id={"ans-" + q.id} sx={{marginLeft: 2}} label="Answer"
                                                           variant="standard"
                                                           onChange={e => setAnswer(q.id!, e)}
                                                />
                                                {!showResult ? <></> :
                                                    isAnswerCorrect(q.id!) ?
                                                        <Chip variant="outlined" label={<CheckIcon/>} color="success"
                                                              style={{float: "right", margin: 15}}/>
                                                        :
                                                        <Chip label={<ClearIcon/>} color="error"
                                                              style={{float: "right", margin: 15}}/>
                                                }

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )

                        })}

            </Grid>

            {started && !showResult ?
                <Button variant="contained" sx={{my: 6}} onClick={handleCheckAnswer} color={"success"} size={"large"} style={{zoom: 3}} >
                    Check Answer
                </Button> :

                started ?
                    <div>
                        <h3> Your score is: {getScore() + "/" + questions?.length} </h3>

                        {
                            getScore() === questions?.length ?
                                <div style={{float: "right", margin: 15}}><h2>Perfect !</h2>
                                    <img src="/img/peach.webp" width={500} alt={"perfect score"}/></div> :
                                <div style={{float: "right", margin: 15}}><h2>Good luck next time !</h2>
                                    <img src="/img/bowser.png" width={500} alt={"failed test"}/></div>
                        }


                        <Button variant="contained" sx={{my: 6}} onClick={() => window.location.reload()}
                                color={"error"}>
                            Start Over
                        </Button>

                    </div>
                    : ""
            }

            {!started ?
                <Button variant="contained" sx={{my: 6}}
                        onClick={newQuestions}>
                    Start new Quiz
                </Button> : ""
            }


        </Container>
    )

}